export const bufferPos = {
  US: 0,
  CN: 0,
  JP: 0,
  IN: 0,
  PH: 0,
  ID: 0,
  FR: 0,
  GB: 0,
  TW: 0,
  MM: 0,
  CM: 0,
  AF: 0,
  IQ: 0,
  PK: 0,
  SY: 0,
  SO: 0,
  SD: 0,
  YE: 0,
  ET: 0,
  ER: 0,
  IR: 0,
  KP: 0,
  LY: 0,
  PS: 0,
  NP: 0,
  CD: 0,
  SS: 0,
  XK: 0,
  LB: 0,
  LK: 0,
  DJ: 0,
  BD: 0,
  CG: 0,
  CF: 0,
  BI: 0,
  EG: 0,
  GQ: 0,
  TM: 0,
  KM: 0,
  DZ: 0,
  BT: 0,
  NG: 0,
  TD: 0,
  KH: 0,
  JO: 0,
  LA: 0,
  VN: 0,
  AO: 0,
  HT: 0,
  RW: 0,
  LR: 0,
  GA: 0,
  MG: 0,
  GW: 0,
  TG: 0,
  MR: 0,
  ST: 0,
  MZ: 0,
  TJ: 0,
  GN: 0,
  UZ: 0,
  NE: 0,
  BF: 0,
  CI: 0,
  ML: 0,
  DO: 0,
  SN: 0,
  BJ: 0,
  KG: 0,
  MN: 0,
  AM: 0,
  MA: 0,
  CU: 0,
  CV: 0,
  AZ: 0,
  UG: 0,
  TN: 0,
  ZW: 0,
  GH: 0,
  SL: 0,
  ZM: 0,
  BO: 0,
  KE: 0,
  NA: 0,
  PG: 0,
  KZ: 0,
  SR: 0,
  TZ: 0,
  TH: 0,
  SA: 0,
  OM: 0,
  MW: 0,
  GM: 0,
  BY: 0,
  SZ: 0,
  NR: 0,
  LS: 0,
  BH: 0,
  MV: 0,
  BW: 0,
  GY: 0,
  EC: 0,
  FJ: 0,
  QA: 0,
  JM: 0,
  KW: 0,
  TL: 0,
  BZ: 0,
  ZA: 0,
  FM: 0,
  PW: 0,
  AL: 0,
  TR: 0,
  BA: 0,
  MD: 0,
  GE: 0,
  MH: 0,
  NI: 0,
  ME: 0,
  MK: 0,
  RU: 0,
  KI: 0,
  TO: 0,
  CO: 0,
  GT: 0,
  SV: 0,
  TV: 0,
  HN: 0,
  WS: 0,
  RS: 0,
  SB: 0,
  VU: 0,
  VE: 0,
  PA: 0,
  DM: 0,
  PY: 0,
  UA: 0,
  MO: 0,
  PE: 0,
  LC: 0,
  CR: 0,
  GD: 0,
  MU: 0,
  AG: 0,
  KN: 0,
  TT: 0,
  VA: 0,
  VC: 0,
  MX: 0,
  UY: 0,
  SC: 0,
  AD: 0,
  BS: 0,
  BN: 0,
  IL: 0,
  SM: 0,
  BB: 0,
  AR: 0,
  CL: 0,
  MC: 0,
  AU: 0,
  BR: 0,
  HK: 0,
  NZ: 0,
  BG: 0,
  HR: 0,
  LI: 0,
  RO: 0,
  CA: 0,
  AE: 0,
  CY: 0,
  EE: 0,
  IS: 0,
  LV: 0,
  LT: 0,
  SK: 0,
  IE: 0,
  PL: 0,
  SI: 0,
  MY: 0,
  HU: 0,
  MT: 0,
  CH: 0,
  CZ: 0,
  GR: 0,
  PT: 0,
  NO: 0,
  KR: 0,
  AT: 0,
  BE: 0,
  NL: 0,
  ES: 0,
  IT: 0,
  LU: 0,
  DK: 0,
  FI: 0,
  SE: 0,
  DE: 0,
  SG: 0
};
