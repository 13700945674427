export const countryIndex = {
  US: 0,
  CN: 1,
  JP: 2,
  IN: 3,
  PH: 4,
  ID: 5,
  FR: 6,
  GB: 7,
  TW: 8,
  MM: 9,
  CM: 10,
  AF: 11,
  IQ: 12,
  PK: 13,
  SY: 14,
  SO: 15,
  SD: 16,
  YE: 17,
  ET: 18,
  ER: 19,
  IR: 20,
  KP: 21,
  LY: 22,
  PS: 23,
  NP: 24,
  CD: 25,
  SS: 26,
  XK: 27,
  LB: 28,
  LK: 29,
  DJ: 30,
  BD: 31,
  CG: 32,
  CF: 33,
  BI: 34,
  EG: 35,
  GQ: 36,
  TM: 37,
  KM: 38,
  DZ: 39,
  BT: 40,
  NG: 41,
  TD: 42,
  KH: 43,
  JO: 44,
  LA: 45,
  VN: 46,
  AO: 47,
  HT: 48,
  RW: 49,
  LR: 50,
  GA: 51,
  MG: 52,
  GW: 53,
  TG: 54,
  MR: 55,
  ST: 56,
  MZ: 57,
  TJ: 58,
  GN: 59,
  UZ: 60,
  NE: 61,
  BF: 62,
  CI: 63,
  ML: 64,
  DO: 65,
  SN: 66,
  BJ: 67,
  KG: 68,
  MN: 69,
  AM: 70,
  MA: 71,
  CU: 72,
  CV: 73,
  AZ: 74,
  UG: 75,
  TN: 76,
  ZW: 77,
  GH: 78,
  SL: 79,
  ZM: 80,
  BO: 81,
  KE: 82,
  NA: 83,
  PG: 84,
  KZ: 85,
  SR: 86,
  TZ: 87,
  TH: 88,
  SA: 89,
  OM: 90,
  MW: 91,
  GM: 92,
  BY: 93,
  SZ: 94,
  NR: 95,
  LS: 96,
  BH: 97,
  MV: 98,
  BW: 99,
  GY: 100,
  EC: 101,
  FJ: 102,
  QA: 103,
  JM: 104,
  KW: 105,
  TL: 106,
  BZ: 107,
  ZA: 108,
  FM: 109,
  PW: 110,
  AL: 111,
  TR: 112,
  BA: 113,
  MD: 114,
  GE: 115,
  MH: 116,
  NI: 117,
  ME: 118,
  MK: 119,
  RU: 120,
  KI: 121,
  TO: 122,
  CO: 123,
  GT: 124,
  SV: 125,
  TV: 126,
  HN: 127,
  WS: 128,
  RS: 129,
  SB: 130,
  VU: 131,
  VE: 132,
  PA: 133,
  DM: 134,
  PY: 135,
  UA: 136,
  MO: 137,
  PE: 138,
  LC: 139,
  CR: 140,
  GD: 141,
  MU: 142,
  AG: 143,
  KN: 144,
  TT: 145,
  VA: 146,
  VC: 147,
  MX: 148,
  UY: 149,
  SC: 150,
  AD: 151,
  BS: 152,
  BN: 153,
  IL: 154,
  SM: 155,
  BB: 156,
  AR: 157,
  CL: 158,
  MC: 159,
  AU: 160,
  BR: 161,
  HK: 162,
  NZ: 163,
  BG: 164,
  HR: 165,
  LI: 166,
  RO: 167,
  CA: 168,
  AE: 169,
  CY: 170,
  EE: 171,
  IS: 172,
  LV: 173,
  LT: 174,
  SK: 175,
  IE: 176,
  PL: 177,
  SI: 178,
  MY: 179,
  HU: 180,
  MT: 181,
  CH: 182,
  CZ: 183,
  GR: 184,
  PT: 185,
  NO: 186,
  KR: 187,
  AT: 188,
  BE: 189,
  NL: 190,
  ES: 191,
  IT: 192,
  LU: 193,
  DK: 194,
  FI: 195,
  SE: 196,
  DE: 197,
  SG: 198,
};
